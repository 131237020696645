<template>
  <div class="batch-search" id="printInfo">
    <div class="top-box">
      <div class="top-title">填报信息</div>
      <div class="archive-info-container" 
        :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
        v-loading="archiveInfoLoading">
          <p v-if="!this.archiveInfoLoading">{{this.yearTag}}年 {{this.provinceNameTag}} {{this.studentNameTag}} {{this.subjectTag}} 分数区间{{this.scoreRangeTag}}</p>
      </div>
    </div>

    <div class="table-container-body">
      <el-table :data="tableData" height="660" stripe class="table-body" ref="tableData" row-key="id"
      :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
      v-loading="tableLoading">
        <el-table-column label=""  width="50" >
          <template v-if="showSortCloumn">
            <i class="el-icon-rank handle-sort"></i>
          </template>
        </el-table-column>
        <el-table-column prop="province" label="省份" :show-overflow-tooltip="true" width="100"></el-table-column>
        <el-table-column prop="city" label="城市" :show-overflow-tooltip="true" width="100"></el-table-column>
        <el-table-column prop="school" label="院校" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="major" label="专业" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="requireSub" label="选科要求" :show-overflow-tooltip="true" width="120"></el-table-column>
        <el-table-column prop="level" label="院校层次" :show-overflow-tooltip="true" width="80"></el-table-column>
        <el-table-column prop="tuition" label="学制/学费" :show-overflow-tooltip="true" width="100"></el-table-column>
        <el-table-column prop="compare" label="招生计划对比2022/2023" :show-overflow-tooltip="true" width="110"></el-table-column>
        <el-table-column prop="segment" label="2022年录取分" :show-overflow-tooltip="true" width="130"></el-table-column>
        <el-table-column prop="forecast" label="参考分" :show-overflow-tooltip="true" width="120"></el-table-column>
        <el-table-column prop="remark" label="备注" :show-overflow-tooltip="true" width="150"></el-table-column>
        <el-table-column label="操作" width="70">
          <template slot-scope="scope" v-if="!showSortCloumn">
            <div class="table-btn-box" >
              <div class="table-btn" @click="handleDelClick(scope.row)">
                删除
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-container-footer">
      <p style="color:#606266;font-size:13px">共 {{this.tableData.length}} 条</p>
      <div class="btn-box" v-if="!this.archiveInfoLoading && !this.tableLoading && this.tableData.length>0">
            <div class="btn" @click="goBack" v-if="!showSortCloumn">返回</div>
            <div class="btn" @click="handleSetDefaultSort" v-if="!showSortCloumn">一键排序</div>
            <div class="btn" @click="handleSetSort" v-if="!showSortCloumn">调整顺序</div>
            <div class="btn" @click="handleSaveSort" v-if="showSortCloumn">完成</div>
            <div class="btn" @click="handlePrintClick">打印</div>
            <download-excel
              class="btn"
              :data="tableData"
              :fields="tableHeader"
              type="xls"
              :header="this.yearTag+'年 '+this.provinceNameTag+' '+this.studentNameTag+' '+this.subjectTag+' 分数区间'+this.scoreRangeTag"
              :name="this.yearTag+'年'+this.provinceNameTag+this.studentNameTag+'填报列表'"
            >
              导出Excel
            </download-excel>
        </div>
    </div>
    
  </div>
</template>
<script>
import JsonExcel from "vue-json-excel";
import printJS from 'print-js';
import Sortable from "sortablejs";
export default {
  name: "batch",
  components: {
    DownloadExcel: JsonExcel,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      tableData: [],
      tableLoading:false,
      archiveInfoLoading:false,
      yearTag:"",
      provinceNameTag:"",
      studentNameTag:"",
      scoreRangeTag:"",
      subjectTag:"",
      studentId:0,
      provinceListLoading:false,
      province:"",
      provinceList:[],
      sortable:null,
      showSortCloumn:false,
      tableHeader:{
        '省份':'province',
        '城市':'city',
        '院校':'school',
        '专业':'major',
        '选科要求':'requireSub',
        '院校层次':"level",
        '学制\\学费':{
          field:"tuition",
          callback: (value) => {
            return `${value.replace("/",'\\')}`;
          },
        },
        '招生计划对比2022\\2023':{
          field:"compare",
          callback: (value) => {
            return `${value.replace("/",'\\')}`;
          },
        },
        '2022年录取分':"segment",
        '参考分':"forecast",
        '备注':"remark"
      }
    };
  },
  methods: {
    initArchiveInfo(){
      this.archiveInfoLoading = true;
      if(!this.studentId || this.studentId==0){
        this.$message({
          message: "学生信息有误!请重试!",
          type: "error",
        });
        this.$router.go(-1);
      }
      this.$http.post(this.$apis.getStudentDetail+"?id="+this.studentId).then((res) => {
        this.archiveInfoLoading = false;
        if (res.data) {
          this.yearTag = res.data.year;
          this.provinceNameTag = res.data.provinceName;
          this.studentNameTag = res.data.name;
          this.subjectTag = res.data.course1 + res.data.course2 + res.data.course3;
          this.scoreRangeTag = (res.data.score-50)+"-"+(res.data.score+50);
        }
      });
    },
    initSelectData(){
      this.provinceListLoading = true;
      //获取省份
      this.$http.post(this.$apis.getProvinceOrCityList+"?type=province").then((res) => {
        this.provinceListLoading = false;
        if (res.data) {
          this.$store.commit("setProvinceList", res.data);
          this.provinceList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
    },
    initTableData(){
      this.tableLoading=true;
      //获取分页表格数据
      this.$http.post(this.$apis.getStudentAspirationList+"?studentId="+this.studentId).then((res) => {
        this.tableLoading=false;
        if (res.data) {
          this.tableData = res.data.map(r=>{
            return {
                id:r.id,
                province:r.schoolProvinceName,
                city:r.schoolCityName,
                school:r.schoolName,
                major:r.majorName,
                requireSub:r.course,
                tuition:r.schoolYear+"/"+r.tuition,
                level:r.schoolNature,
                compare:r.planRate,
                // segment:r.preYearScore+"/"+r.preYearScoreRanking,
                // forecast:r.estimatedScore+"/"+r.estimatedRanking,
                segment:r.preYearScore,
                forecast:r.estimatedScore,
                remark:r.note == null?"":r.note
            }
          });
        }
      });
    },
    handleDelClick(row){
      this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var data ={
            majorIds: [ row.id ],
            studentId: this.studentId
          };
          this.$http.post(this.$apis.deleteAspiration,data).then((res) => {
          if (res.code==200 && res.message==="SUCCESS") {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.initTableData();
              }else{
                this.$message({
                  message: "删除失败!原因:"+res.message,
                  type: "error",
                });
              }
          });
        });
    },
    handleDelSelectClick(){
      this.$refs.tableData.clearSelection();
    },
    handlePrintClick(){
      printJS({
        printable:this.tableData,
        type:'json',
        properties:[
            {displayName:'省份',field:'province',columnSize:'6%'},
            {displayName:'城市',field:'city',columnSize:'6%'},
            {displayName:'院校',field:'school'},
            {displayName:'专业',field:'major'},
            {displayName:'选科要求',field:'requireSub'},
            {displayName:'院校层次',field:"level"},
            {displayName:'学制/学费',field:"tuition"},
            {displayName:'招生计划对比2022/2023',field:"compare"},
            {displayName:'2022年录取分',field:"segment"},
            {displayName:'参考分',field:"forecast"},
            {displayName:'备注',field:"remark"}
          ],
        header:`<div style="display: flex;flex-direction: column;text-align: center">
                  <h3>${this.yearTag}年 ${this.provinceNameTag} ${this.studentNameTag} ${this.subjectTag} 分数区间${this.scoreRangeTag}</h3>
                </div>`,
        gridHeaderStyle: 'border: 1px solid #000;text-align:center;font-size: 1px;',
        gridStyle: 'border: 1px solid #000;text-align:center;font-size: 1px;'
      });
    },
    goBack() {
      this.$router.push({
        name: "applicationInfo",
        params:{
          studentId: this.studentId
        }
      });
    },
    handleSetSort() {
      this.showSortCloumn = true;
      const el = this.$refs.tableData.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      this.sortable = Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        handle: '.handle-sort',
        animation: 150,
        setData: function(dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        onEnd: evt => {
          const targetRow = this.tableData.splice(evt.oldIndex, 1)[0];
          this.tableData.splice(evt.newIndex, 0, targetRow);
        }
      });
    },
    handleSetDefaultSort() {
      if (this.tableData?.length > 0) {
        const majorIds = [];
        let sortTable = this.tableData.sort((a,b) => b.forecast - a.forecast);
        sortTable.forEach((item, index) => {
          majorIds.push(item.id);
        })
        const data = {
          studentId: this.studentId,
          majorIds: majorIds
        };

        this.$http.post(this.$apis.updateSortByStudent, data).then((res) => {
          this.$message({
            type: 'success',
            message: '排序成功!'
          });
          this.initTableData()
        })
      }
    },
    handleSaveSort(){
      if (this.tableData?.length > 0) {
        const majorIds = [];
        this.tableData.forEach((item, index) => {
          majorIds.push(item.id);
        })
        const data = {
          studentId: this.studentId,
          majorIds: majorIds
        };

        this.$http.post(this.$apis.updateSortByStudent, data).then((res) => {
          this.$message({
            type: 'success',
            message: '保存成功!'
          });
          this.showSortCloumn = false;
        })
        // .error(err => {
        //   this.$message({
        //     type: 'error',
        //     message: '保存顺序失败!'
        //   });
        //   this.showSortCloumn = false;
        // });
      } else {
        this.showSortCloumn = false;
      }
    
      //Sortable.destroy(this.sortable);
      console.log(this.sortable);
    }
  },
  mounted(){
    this.studentId = this.$route.params.studentId;
    this.initArchiveInfo();
    this.initSelectData();
    this.initTableData();
  },
};
</script>
<style lang="less" scoped>
.batch-search {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 5px;
  border: 1px solid rgba(230, 230, 230, 100);

  .top-box {
    display: flex;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid rgba(230, 230, 230, 100);

    .archive-info-container{
        padding-left: 400px;
        p{
          text-align: center;
          font-size: 24px;
        }
    }
    .top-title {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      position: relative;
    }
    .top-title::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 27px;
      width: 3px;
      background-color: #ff450b;
    }

  }
  
  .form-group{
      padding: 0px 20px;
    .form-item {
      display: flex;
      align-items: center;
      padding: 15px 0;
      .label {
        display: flex;
        justify-content: flex-end;
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 70px;
        padding-right:10px;
      }
      .input-box {
          display: flex;
          width: calc(100% - 100px);
      }
      .required-label{
            color: rgb(255, 0, 0);
      }
      .action-link{
          font-size: 16px;
          cursor:pointer;
          color: #ff450b;
          padding-right: 20px;
      }
    }
  }
  .form-control{
      width: 100%;
  }
  .table-container-body {
    .table-body{
      width: 100%;
      overflow: auto;
    }
    .handle-sort{
      cursor: pointer;
      font-size: 20px;
    }
    .selected{
      background-color: #f9c7c8;
      border: solid red 1px !important;
    }
    .table-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .table-btn {
        cursor: pointer;
        width: 60px;
        line-height: 28px;
        border-radius: 14px;
        color: rgba(121, 160, 241, 100);
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(121, 160, 241, 100);
      }
    }
  }
  .table-container-footer{
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    .btn-box {
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
  .bottom-box{
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    .btn-box {
      padding: 0px 20px;
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
}
</style>
